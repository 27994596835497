<template>
  <div>
    <div class="A" id="A">
      <div class="F-F">
        <div class="map-bg-box" ref="mapBgBoxRef" id="panzoom-element">
          <img class="img" src="../../assets/image/最终版.png" alt="" />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation"
            @click="start(1)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation2"
            @click="start(2)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation3"
            @click="start(3)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation4"
            @click="start(4)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation5"
            @click="start(5)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation6"
            @click="start(6)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation7"
            @click="start(7)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation8"
            @click="start(8)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation9"
            @click="start(9)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation10"
            @click="start(10)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation11"
            @click="start(11)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation12"
            @click="start(12)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation13"
            @click="start(13)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation14"
            @click="start(14)"
          />
          <img
            id="point"
            src="../../assets/image/透明.png"
            class="point"
            :style="pointLocation15"
            @click="start(15)"
          />
        </div>
        <div class="Promptpanel" v-if="isShowPrompt">
          <div class="Promptpanel-box">
            <img src="../../assets/icon/未标题-1.gif" alt="" class="shouzhi" />
            <div class="tooltip-box">
              <div>双指放大,点击摄像头图标可查看AR全景</div>
              <div>({{ count }}s后自动关闭提示)</div>
              <button
                class="grasped-btn"
                v-if="isShowPrompt"
                @click="closePrompt"
              >
                我知道啦
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Panzoom from "@panzoom/panzoom";

export default {
  data() {
    return {
      isShowPrompt: true,
      count: 7,
      str: "userKey",
      x: 0,
      y: 0,
      old: {
        x: 0,
        y: 0,
      },
      // 缩放比例
      scale: 1,
      // panzoom 实例
      pan: null,
      // panzoom配置
      panOption: {
        startX: 0,
        startY: 0,
        startScale: 1,
      },
      pointX: 136.5, // Initial X coordinate of the point
      pointY: 452,
      pointX2: 121, // 0.175
      pointY2: 435, /// 0.10125
      pointX3: 181.5, //0.1625
      pointY3: 365,
      pointX4: 198,
      pointY4: 404.5,
      pointX5: 130,
      pointY5: 264,
      pointX6: 178,
      pointY6: 296,
      pointX7: 172,
      pointY7: 316,
      pointX8: 235.5,
      pointY8: 318,
      pointX9: 210.5,
      pointY9: 337,
      pointX10: 211,
      pointY10: 282.5,
      pointX11: 146,
      pointY11: 400,
      pointX12: 173,
      pointY12: 392,
      pointX13: 135,
      pointY13: 421,
      pointX14: 161.5,
      pointY14: 362,
      pointX15: 154,
      pointY15: 341,
      pointX16: 101,
      pointY16: 286.5,
      pointX17: 95,
      pointY17: 289.5,
      pointX18: 108,
      pointY18: 272,
      pointX19: 117,
      pointY19: 267.5,
    };
  },
  computed: {
    pointLocation() {
      return {
        left: `${this.pointX * this.scale}px`, // 定位
        top: `${this.pointY * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation2() {
      return {
        left: `${this.pointX2 * this.scale}px`, // 定位
        top: `${this.pointY2 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation3() {
      return {
        left: `${this.pointX3 * this.scale}px`, // 定位
        top: `${this.pointY3 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation4() {
      return {
        left: `${this.pointX4 * this.scale}px`, // 定位
        top: `${this.pointY4 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation5() {
      return {
        left: `${this.pointX5 * this.scale}px`, // 定位
        top: `${this.pointY5 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation6() {
      return {
        left: `${this.pointX6 * this.scale}px`, // 定位
        top: `${this.pointY6 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation7() {
      return {
        left: `${this.pointX7 * this.scale}px`, // 定位
        top: `${this.pointY7 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation8() {
      return {
        left: `${this.pointX8 * this.scale}px`, // 定位
        top: `${this.pointY8 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation9() {
      return {
        left: `${this.pointX9 * this.scale}px`, // 定位
        top: `${this.pointY9 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation10() {
      return {
        left: `${this.pointX10 * this.scale}px`, // 定位
        top: `${this.pointY10 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation11() {
      return {
        left: `${this.pointX11 * this.scale}px`, // 定位
        top: `${this.pointY11 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation12() {
      return {
        left: `${this.pointX12 * this.scale}px`, // 定位
        top: `${this.pointY12 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation13() {
      return {
        left: `${this.pointX13 * this.scale}px`, // 定位
        top: `${this.pointY13 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation14() {
      return {
        left: `${this.pointX14 * this.scale}px`, // 定位
        top: `${this.pointY14 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation15() {
      return {
        left: `${this.pointX15 * this.scale}px`, // 定位
        top: `${this.pointY15 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation16() {
      return {
        left: `${this.pointX16 * this.scale}px`, // 定位
        top: `${this.pointY16 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation17() {
      return {
        left: `${this.pointX17 * this.scale}px`, // 定位
        top: `${this.pointY17 * this.scale}px`, // 定位
        width: `${6 * this.scale}px`, // 图标尺寸
        height: `${6.5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation18() {
      return {
        left: `${this.pointX18 * this.scale}px`, // 定位
        top: `${this.pointY18 * this.scale}px`, // 定位
        width: `${9 * this.scale}px`, // 图标尺寸
        height: `${5 * this.scale}px`, // 图标尺寸
      };
    },
    pointLocation19() {
      return {
        left: `${this.pointX19 * this.scale}px`, // 定位
        top: `${this.pointY19 * this.scale}px`, // 定位
        width: `${18 * this.scale}px`, // 图标尺寸
        height: `${5 * this.scale}px`, // 图标尺寸
      };
    },
  },
  async mounted() {
    // this.proportion()

    await this.initPanzoomOption();
    // 初始化设置比例
    this.setMapBgScale();
    // 初始化缩放
    this.initPanzoom();
    // 监听比例变化
    window.addEventListener("resize", () => {
      this.setMapBgScale;
    });
    //判断本地是否有userKey，有就不显示提示，没有就显示提示
    if (localStorage.getItem("userKey")) {
      this.isShowPrompt = false;
    } else {
      localStorage.setItem("userKey", this.str);
      this.isShowPrompt = true;
      this.$nextTick(() => {
        setInterval(() => {
          if (this.count >= 2) {
            this.count -= 1;
          } else {
            this.isShowPrompt = false;
          }
        }, 1000);
      });
    }
  },
  methods: {
    closePrompt() {
      this.isShowPrompt = false;
    },
    start(e) {
      sessionStorage.setItem("panOption", JSON.stringify(this.panOption));
      // uni.redirectTo({
      //     url: `/pagevr/vr/panoramaOne` + `?msg=${e}`,
      // });
      this.$router.push(`/ARone?=` + `${e}`);
      document;
    },
    // 设置比例
    setMapBgScale() {
      this.$nextTick(() => {
        // 这里以375设计稿为例
        const baseWidth = 375;
        const imgWidth = this.$refs.mapBgBoxRef.clientWidth;
        this.scale = imgWidth / baseWidth;
      });
    },
    // 初始化缩放参数
    initPanzoomOption() {
      try {
        const _panOption = JSON.parse(sessionStorage.getItem("panOption"));

        this.panOption = {
          startX: _panOption?.startX || 0,
          startY: _panOption?.startY || 0,
          startScale: _panOption?.startScale || 0,
        };
      } catch {
        this.panOption = {
          startX: 0,
          startY: 0,
          startScale: 1,
        };
      }
    },
    // 初始化缩放
    initPanzoom() {
      this.$nextTick(() => {
        const panDom = document.getElementById("panzoom-element");
        this.pan = Panzoom(panDom, {
          maxScale: 8,
          minScale: 1,
          startScale: this.panOption.startScale || 1,
          startX: this.panOption.startX || 0,
          startY: this.panOption.startY || 0,
          panOnlyWhenZoomed: false,
        });
        panDom.parentElement.addEventListener("wheel", this.pan.zoomWithWheel);
        panDom.addEventListener("panzoomzoom", () => {
          if (this.pan.getScale() === 1) {
            this.pan.reset({
              startX: 0,
              startY: 0,
              startScale: 1,
              panOnlyWhenZoomed: true,
            });
          } else {
            this.pan.setOptions({ panOnlyWhenZoomed: false });
          }
          this.panOption.startScale = this.pan.getScale() || 1;
        });
        panDom.addEventListener("panzoomend", () => {
          this.panOption.startX = this.pan.getPan().x || 0;
          this.panOption.startY = this.pan.getPan().y || 0;
        });
        sessionStorage.removeItem("panOption");
      });
    },
  },
  beforeDestroy() {
    // 页面销毁移除监听
    window.removeEventListener("resize", this.setMapBgScale);
    // 销毁panzoom
    if (this.pan) {
      this.pan.destroy();
      this.pan = null;
    }
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
// .A{
//     position: relative;
// }
.F-F {
  background-color: #f0fdf8;
  display: flex;
  color: #ffffff;
  font-size: 10px;
  width: 100%;
  height: 100vh;
  position: relative;
  // 是用了less嵌套使用即可
  .map-bg-box {
    width: 100%;
    height: max-content;
    position: relative;

    .img {
      width: 100%;
    }

    .point {
      position: absolute;
      cursor: pointer;
      // transition: width .5s 0s linear
    }
  }
}
.Promptpanel {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  .Promptpanel-box {
    width: 100%;
    position: absolute;
    top: 21%;
  }
  .shouzhi {
    display: block;
    width: 35%;
    margin: 0 auto;
    // position: absolute;
    // top: 21%;
    // left: 31%;
  }
  .tooltip-box {
    font-size: 17px;
    text-align: center;
    padding-top: 25px;
  }
  .grasped-btn {
    width: 100px;
    height: 30px;
    font-size: 15px;
    color: #ffffff;
    background: #0283ef;
    border-radius: 10px;
    border: none;
    margin-top: 20px;
  }
}
</style>
