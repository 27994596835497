<template>
  <div class="inspection" ref="wrapperRef">
    <!-- 全景图容器 -->
    <div id="inner-quanjing"></div>
  </div>
</template>
<script>
import { Viewer } from "photo-sphere-viewer";
export default {
  data() {
    return {
      PSV: null, // 全景图对象
      imgReturn: false, // 查看照片弹窗是否显示
      dialogVisible: false, // 是否显示全景图弹窗
      superiorPanoramaCode: "", // 上级全景图编码
      detailList: {},
      // panoramaUrl: require("../assets/image/大车沟停车场.jpg"),
    };
  },

  mounted() {
    console.log(document.querySelectorAll("head meta"));
    this.$nextTick(() => {
      this.initPhotoSphere();
    });
    // 监听比例变化
    window.addEventListener("resize", this.initScale);
  },
  methods: {
    // 初始化缩放比例
    initScale() {
      this.$nextTick(() => {
        let scale = window.visualViewport.scale;
        if (scale > 1) {
          this.$refs.wrapperRef.style.transformOrigin = "left top";
          this.$refs.wrapperRef.style.transform = `scale(${
            1 / scale
          }) translateZ(0)`;
        }
        if (this.PSV) {
          this.PSV.resize();
        }
      });
    },
    // 全景图初始化
    initPhotoSphere() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.initScale();
      let star = window.location.href.indexOf("?");
      let e = window.location.href.slice(star + 2, window.location.href.length);
      this.sendurl(e);
      document.getElementById("inner-quanjing").innerHTML = "";
      document.getElementById("inner-quanjing").style.top = "0";
      this.PSV = null;
      this.PSV = new Viewer({
        panorama: this.panoramaUrl, // 全景图地址
        container: document.getElementById("inner-quanjing"), // 全景图的容器
        size: { width: "100%", height: "100%" }, // 全景图容器大小
        navbar: ["autorotate",
        "zoom",
        "moveUp",
        "moveDown",
        "moveLeft",
        "moveRight"],
      });
      console.log(this.PSV);
    },
    sendurl(e) {
      console.log(e, typeof e, "这是e");
      if (e === "1") {
        this.panoramaUrl = require("../assets/image/1号门.jpg");
      }
      if (e === "2") {
        this.panoramaUrl = require("../assets/image/p3停车场.jpg");
      }
      if (e === "3") {
        this.panoramaUrl = require("../assets/image/流云台.jpg");
      }
      if (e === "4") {
        this.panoramaUrl = require("../assets/image/农行会客厅.jpg");
      }
      if (e === "5") {
        this.panoramaUrl = require("../assets/image/蓉城时光街.jpg");
      }
      if (e === "6") {
        this.panoramaUrl = require("../assets/image/散花楼.jpg");
      }
      if (e === "7") {
        this.panoramaUrl = require("../assets/image/散花楼正面.jpg");
      }
      if (e === "8") {
        this.panoramaUrl = require("../assets/image/天府人居馆.jpg");
      }
      if (e === "9") {
        this.panoramaUrl = require("../assets/image/天府人居展区.jpg");
      }
      if (e === "10") {
        this.panoramaUrl = require("../assets/image/天府眼.jpg");
      }
      if (e === "11") {
        this.panoramaUrl = require("../assets/image/未来剧场.jpg");
      }
      if (e === "12") {
        this.panoramaUrl = require("../assets/image/未来园艺展区.jpg");
      }
      if (e === "13") {
        this.panoramaUrl = require("../assets/image/雪山桥.jpg");
      }
      if (e === "14") {
        this.panoramaUrl = require("../assets/image/中华园艺展区1.jpg");
      }
      if (e === "15") {
        this.panoramaUrl = require("../assets/image/中华园艺展区2.jpg");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.inspection {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  #inner-quanjing {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.detail {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 0 3.2rem;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    height: 11.73rem;
    font-size: 4.8rem;
    font-weight: 500;
    background-color: #fcfffe;

    .left {
      width: 2.8rem;
      height: 5.07rem;
    }
  }

  &-content {
    color: #333;

    &-item {
      width: 100%;
      height: 10.8rem;
      font-size: 3.73rem;
      display: flex;
      justify-content: space-between;
      padding: 0 3.2rem;
      box-sizing: border-box;
      align-items: center;
      border-bottom: 0.5px solid #f0f2fc;

      .item-val {
        color: #666;
      }
    }
  }
}
</style>
